Tabs = Garnish.Base.extend({
    $container: null,

    $tabMenu: null,
    $tabMenuLink: null,
    $tabContent: null,


    init(container) {
        this.$container = container
        this.$tabMenu = container.find('.tabs-menu')
        this.$tabMenuLink = container.find('.tabmenu')
        this.$tabContent = container.find('.tabcontent')

        this.addListener(this.$tabMenuLink, 'click', this.handleTabSwitch)
    },

    handleTabSwitch(e) {
        let selectedMenu = $(e.currentTarget)
        let targetContent = selectedMenu.data('content')

        this.$tabContent.hide()
        this.$tabContent.attr('aria-hidden', 'true')

        this.$tabMenuLink.removeClass('active')
        this.$tabMenuLink.attr('aria-selected', 'false')

        $(`#${targetContent}`).show()
        $(`#${targetContent}`).attr('aria-hidden', 'false')

        selectedMenu.addClass('active')
        selectedMenu.attr('aria-selected', 'true')
    }
})